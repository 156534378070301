<template>
  <div class="flex flex-col w-full md:w-72 text-gray-700 bg-white flex-shrink-0 md:h-screen md:fixed shadow-right z-50">
    <div class="flex-shrink-0 px-8 py-4 flex flex-row items-center justify-between">
      <img class="py-4 h-24" src="@/assets/images/logo.svg" alt="">
    </div>
    <nav class="flex-1 md:block px-4 pb-4 md:pb-0 md:overflow-y-auto">
      <router-link to="/home">
        <p class="text-secondary ml-2 text-sm">Mijn mappen</p>
      </router-link>
      <router-link v-for="folder in folders" :key="folder" class="flex items-center block px-2 py-2 mt-2 text-sm font-semibold text-gray-900 rounded-lg hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline" :class="[currentPath(folder) ? 'bg-gray-200' : 'bg-transparent']" :to="'/home?path=' + folder">
        <img v-if="!currentPath(folder)" src="@/assets/images/folder-gray.svg" class="w-7 mr-3 cursor-pointer">
        <img v-if="currentPath(folder)" src="@/assets/images/folder-red.svg" class="w-7 mr-3 cursor-pointer">
        <p class="font-bold" :class="[currentPath(folder) ? 'text-black' : 'text-secondary']">{{ folder }}</p>
      </router-link>
      <router-link class="flex items-center block px-2 py-2 mt-2 text-sm font-semibold text-gray-900 rounded-lg hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline" :class="[currentPath('Overige') ? 'bg-gray-200' : 'bg-transparent']" :to="'/home?path=Overige'">
        <img v-if="!currentPath('Overige')" src="@/assets/images/folder-gray.svg" class="w-7 mr-3 cursor-pointer">
        <img v-if="currentPath('Overige')" src="@/assets/images/folder-red.svg" class="w-7 mr-3 cursor-pointer">
        <p class="font-bold" :class="[currentPath('Overige') ? 'text-black' : 'text-secondary']">Overige</p>
      </router-link>
      <div v-if="roles.includes('Admin')">
        <p class="text-secondary ml-2 text-sm mt-3">Admin</p>
        <router-link class="block px-4 py-2 mt-2 text-sm font-semibold text-gray-900 rounded-lg hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline" :class="[currentPath('/folders') ? 'bg-gray-200' : 'bg-transparent']" to="/folders">Folders</router-link>
        <router-link class="block px-4 py-2 mt-2 text-sm font-semibold text-gray-900 rounded-lg hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline" :class="[currentPath('/users') ? 'bg-gray-200' : 'bg-transparent']" to="/users">Gebruikers</router-link>
      </div>
    </nav>
    <div>
      <div class="mx-4">
        <hr>
      </div>
      <div class="flex">
        <p class="flex-auto m-4">
          {{ user.name }}
        </p>
        <p class="flex-none m-4 cursor-pointer">
          <font-awesome-icon icon="sign-out-alt" @click="logout()"/>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      user: '',
      roles: [],
      folders: []
    }
  },
  mounted () {
    this.user = this.$store.getters.user
    this.roles = this.$store.getters.roles
    this.folders = this.$store.getters.rootDirectories
  },
  methods: {
    logout () {
      this.$store.dispatch('logout').then(() => {
        location.reload()
      })
    },
    currentPath (path) {
      if (this.$route.query.path) return (this.$route.query.path.split('/')[0] === path)
      return this.$route.path.startsWith(path)
    }
  }
}
</script>

<template>
  <div class="layout bg-gray-200 w-full">
    <div class="md:flex flex-col md:flex-row md:min-h-screen w-full">
      <SideBar v-if="this.$store.getters.user && this.$store.getters.files && !hidden"/>
      <div class="flex-auto md:ml-72 bg-darker" v-if="this.$store.getters.user && this.$store.getters.files">
        <router-view v-on:hide="hideSideBar"/>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from '../components/SideBar.vue'
export default {
  name: 'Layout',
  components: {
    SideBar
  },
  data () {
    return {
      hidden: false
    }
  },
  computed: {
    user () {
      return this.$store.getters.user
    }
  },
  watch: {
    user (newValue, oldValue) {
      if (newValue && !this.$store.getters.files) this.$store.dispatch('getFiles')
    }
  },
  mounted () {
    if (!this.$store.getters.files && this.$store.getters.user) this.$store.dispatch('getFiles')
  },
  methods: {
    hideSideBar (value) {
      this.hidden = value
    }
  }
}
</script>
